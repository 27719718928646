/* Third Party -----------------------------------------------------*/

import $ from 'jquery';
import {TweenMax}  from "gsap";


/* Imports ---------------------------------------------------------*/

// import './templates'; 

/* Variables -------------------------------------------------------*/

let $body = $('body');

/*------------------------------------------------------------------
 Mobile Navigation
 ------------------------------------------------------------------*/

var $hamburger = $('#hamburger');
var $nav = $('.js-nav');
var $lineOne = $hamburger.find('span:first-child');
var $lineTwo = $hamburger.find('span:nth-child(2)');
var $lineThree = $hamburger.find('span:last-child');
var hamburgerAnimation = new TimelineMax({paused: true});
var hamburgerCanClick = 1;

hamburgerAnimation
	.to([$lineOne, $lineThree], 0.3, {top: "50%"}, 'start')
	.set($lineTwo, {autoAlpha: 1}, 'start')
	.set($lineTwo, {autoAlpha: 0}, 'mid')
	.to($lineOne, 0.3, {rotation: 45}, 'end')
	.to($lineThree, 0.3, {rotation: -45}, 'end');

$hamburger.on('click', function(){
	var $this = $(this)
	if (hamburgerCanClick) {
		hamburgerCanClick = 0;	
		if ($this.hasClass('open')) {
			$this.removeClass('open');
			hamburgerAnimation.reverse();
			transitionHeight($nav, 0);
		}else {
			$this.addClass('open');
			hamburgerAnimation.restart();
			transitionHeight($nav, 'calc(100vh - 30rem)');
		}
		setTimeout(function(){
			hamburgerCanClick = 1;
		}, 500);
	}
})

function transitionHeight($element, height) {
	var currentHeight = $element.height();
	var newHeight;


	$element.css({
		'height': height
	});

	newHeight = $element.height();

	$element.css({
		'height': currentHeight
	});

	TweenLite.to($element, 0.5, {height: newHeight});
}